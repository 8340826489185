<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewPageSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="val => $emit('update:is-add-new-page-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{
            typeForm === 'add'
            ? 'Add New'
            : typeForm === 'edit'
              ? 'Edit'
              : 'Detail'
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Warehouse" rules="required">
            <b-form-group label="Warehouse" label-for="warehouse">
              <v-select id="warehouse" v-model="form.warehouse_id" label="warehouse_name" :filterable="false"
                :options="warehouseOptions" @search="warehouseOnSearch" :reduce="(e) => {
                  return e.id
                }">
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.warehouse_name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.warehouse_name }}
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Produk" rules="required">
            <b-form-group label="Produk" label-for="product">
              <v-select id="product" v-model="form.kd_produk" label="kd_produk" :filterable="false"
                :options="productOptions" :reduce="e => {
                  return e.kd_produk
                }
                  " @search="productOnSearch">
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ `${option.kd_produk} - ${option.nama_produk}` }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ `${option.kd_produk}` }}
                  </div>
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Quantity" rules="required">
            <b-form-group label="Quantity" :state="getValidationState(validationContext)">
              <b-form-input id="quantity" v-model="form.quantity" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Quantity" type="number" :readonly="!canEdit" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Keterangan" rules="required">
            <b-form-group label="Keterangan" label-for="note">
              <b-form-Textarea id="note" v-model="form.note" autofocus :state="getValidationState(validationContext)" trim
                placeholder="Keterangan" :readonly="!canEdit" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div v-if="typeForm !== 'show'" class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="isLoading">
              <span>Submit</span>
              <b-spinner v-if="isLoading" small class="ml-1" />
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { typeVoucherOptions } from '@core/utils/constant'
import store from '@/store'
import service from '@/components/Table/service'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BFormDatepicker,
    BButton,
    vSelect,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPageSidebarActive',
    event: 'update:is-add-new-page-sidebar-active',
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: 'add',
    },
    data: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      productOptions: [],
      warehouseOptions: [],
    }
  },
  methods: {
    productOnSearch(search, loading) {
      this.productFilter(search, loading, this)
    },
    productFilter: _.debounce((search, loading, vm) => {
      loading(true)
      service.index({ url: 'produk', query: `sort_by=nama_produk&direction=asc&q=${search}` }).then(res => {
        vm.productOptions = res.data.data
      })
        .finally(() => {
          loading(false)
        })
    }, 1000),
    warehouseOnSearch(search, loading) {
      this.warehouseFilter(search, loading, this)
    },
    warehouseFilter: _.debounce((search, loading, vm) => {
      loading(true)
      service.index({ url: 'warehouse-commerce', query: `sort_by=warehouse_name&direction=asc&q=${search}` }).then(res => {
        vm.warehouseOptions = res.data.data
      })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  mounted() {
    service.index({ url: 'warehouse-commerce', query: 'sort_by=created_at&direction=desc' }).then(res => {
      this.warehouseOptions = res.data.data
    })
  },
  setup(props, { emit, root }) {
    const blankFormData = {
      return_receive_id: root.$route.params.id,
      warehouse_id: null,
      kd_produk: null,
      quantity: 0,
      note: null,
    }

    const canEdit = ref(true)

    const form =
      props.data !== null
        ? ref(props.data)
        : ref(JSON.parse(JSON.stringify(blankFormData)))
    const resetpageData = () => {
      form.value = JSON.parse(JSON.stringify(blankFormData))
    }

    watch(
      () => props.isAddNewPageSidebarActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          form.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          form.value = props.data
        } else {
          canEdit.value = true
          form.value = JSON.parse(JSON.stringify(blankFormData))
        }
      }
    )

    const onSubmit = () => {
      emit('onSubmit', form.value)
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData)

    return {
      tglLahir: null,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,

      form,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
